<template>
	<div>
		<div v-if="isLnag">
			<Header />
		</div>
		<div v-else>
			<HeaderEn />
		</div>
		<keep-alive>
			<router-view />
		</keep-alive>
		<div v-if="isLnag">
			<Footer />
		</div>
		<div v-else>
			<FooterEn />
		</div>
	</div>
</template>

<script>
	import Footer from "./Footer.vue";
	import FooterEn from "./en/Footer.vue";
	import Header from "./Header.vue";
	import HeaderEn from "./en/Header.vue";
	export default {
		name: "LayoutPage",
		components: {
			Footer,
			Header,
			HeaderEn,
			FooterEn
		},


		created() {
			console.log(this.global.language, 'created')
		},
		methods: {},
		computed: {
			isLnag() {
				console.log(this.$route.path, 'this.$route.path')
				
				if(this.$route.path == '/en'){
					return false
				} 
				if (this.global.language == "chinese") {
					return true
				}
				if (this.global.language == "english") {
					return false
				}

			},
		}, 
	};
</script>

<styles scoped>

	</style>