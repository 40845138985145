const window = {
  state: {
    screenWidth: null,
  },
  mutations: {
    SET_SCREENWIDTH: (state, screenWidth) => {
      state.screenWidth = screenWidth
    },
  },
  actions: {
    set_screenWidth({ commit },screenWidth) {
      commit('SET_SCREENWIDTH', screenWidth)
    }
  }
}


export default window
