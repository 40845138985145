import Vue from "vue";
import App from "./App.vue";

/* 路由 */
import router from "./router";
import "./router/permission.js";

//百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, { ak: "0Ytkqv4zIpfFtddwCRiLIlMgEhacXDRG" });

// 全局引入样式
import "@/style/common.css";

// vuex
import store from "./store";
// 全局监听 （有使用vuex）
import "./utils/window.js";

/* 重置样式 */
import "./relyon/bootstrap/reset.min.css";
/* bootstarp */
import "./relyon/bootstrap/bootstrap.min.css";
import "./relyon/bootstrap/bootstrap.min";

// swiper插件

/* jquery */
import "jquery";

/* animate.css */
import "animate.css";

/**全局变量 */
import global from "./utils/global.js";
// 图片路径前缀
Vue.prototype.imgUrl = global.imgUrl;

Vue.prototype.global = global;

Vue.config.productionTip = false;

new Vue({
	// components: { App },
	router,
	store,
	// components: { App },
	// template: '<App/>',
	render: (h) => h(App),
}).$mount("#app");
