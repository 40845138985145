<template>
	<!-- 头部整体盒子 -->
	<div id="header" class="container-fuild">
		<!-- 电脑导航 -->
		<div class="header-nav container-fuild visible-lg visible-md">
			<!-- 导航logo -->
			<div class="header-nav-logo">
				<img src="http://official.feizhisoft.com/picture/logo_1.png" />
			</div>
			<div class="lang" @click="checkLanguage">
				<img class="lang_icon" src="https://official.feizhisoft.com/picture/home/lang_icon.png" alt="">
				英文
			</div>
			<!-- 导航内容 -->
			<ul class="header-nav-wrapper">
				<li v-for="(item, index) in navList" :key="index" :class="index == navIndex ? 'active' : ''"
					@click="topage(index, item.name, item.path)">
					<router-link :to="item.path">
						{{ item.name }}
						<span v-if="item.children.length > 0" class="glyphicon glyphicon-menu-down"></span>
						<i class="underline"></i>
					</router-link>
					<dl v-if="item.children.length > 0">
						<dt v-for="(i, n) in item.children" :key="n" @click.stop="topageitem(index, i.path)">
							<span>{{ i.name }}</span>
						</dt>
					</dl>
				</li>
			</ul>

		</div>
		<!-- 手机导航 -->
		<div class="header-nav-m container-fuild hidden-lg hidden-md">
			<div class="header-nav-m-logo">
				<img class="center-block" src="http://official.feizhisoft.com/picture/logo_1.png" alt="logo" />
			</div>
			<div class="lang-phone" @click="checkLanguage">
				<img class="lang_icon-phone" src="https://official.feizhisoft.com/picture/home/lang_icon.png" alt="">
				英文
			</div>
			<!-- 导航栏 -->
			<div class="header-nav-m-menu text-center">
				{{ menuName }}
				<!-- data-toggle="collapse" data-target="#menu" -->
				<div class="header-nav-m-menu-wrapper" data-toggle="collapse" @click="menuClick">
					<span :class="menuClass"></span>
				</div>
				<!-- 导航内容 -->
				<ul id="menu" class="header-nav-m-wrapper collapse">
					<div v-for="(item, index) in navList" :key="index">
						<li :class="index == navIndex ? 'active header-nav-m-wrapperli' : 'header-nav-m-wrapperli'"
							@click="navClick_m(item)" data-toggle="collapse">
							<a class="header-nav-m-wrapperlia">
								{{ item.name }}
							</a>
							<div v-if="item.children.length > 0" class="header-nav-m-menu-wrapper"
								data-toggle="collapse" @click.stop="itemmenuClick(item, index)">
								<span :class="item.menuClass"></span>
							</div>
						</li>
						<ul :id="'menuitem' + index" class="collapse" v-if="item.children.length > 0">
							<li v-for="(i, n) in item.children" :key="n" class="header-nav-m-wrapperli"
								data-toggle="collapse" @click="topage_m(i, index)">
								<a class="header-nav-m-wrapperlia_item">
									{{ i.name }}
								</a>
							</li>
						</ul>
					</div>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "Header",
		data() {
			return {
				navIndex: 0,
				menuName: "首页",
				menuClass: "glyphicon glyphicon-menu-down",
				navList: [{
						name: "首页",
						pathname: "homePage",
						path: "/homePage",
						children: [],
					},
					{
						name: "关于我们",
						pathname: "aboutUs",
						path: "/aboutUs",
						children: [],
					},
					{
						name: "业务板块",
						pathname: "businessSegments",
						path: "/businessSegments",
						menuClass: "glyphicon glyphicon-menu-down",
						children: [{
								name: "数字化解决方案",
								path: "/businessSegments/digitalSolutions",
								pathname: "digitalSolutions",
							},
							{
								name: "大数据中台",
								path: "/businessSegments/bigData",
								pathname: "bigData",
							},
							{
								name: "人工智能",
								path: "/businessSegments/artificialIntelligence",
								pathname: "artificialIntelligence",
							},
							{
								name: "移动应用",
								path: "/businessSegments/mobileApps",
								pathname: "mobileApps",
							},
							{
								name: "车载电子嵌入式",
								path: "/businessSegments/electronicEmbedded",
								pathname: "electronicEmbedded",
							}, {
								name: "定制化开发",
								path: "/businessSegments/customization",
								pathname: "customization",
							},
							// {
							// 	name: "微信开发",
							// 	path: "/software/smartTown",
							// },
							// {
							// 	name: "小程序",
							// 	path: "/software/bigData",
							// },
							// {
							// 	name: "网站开发",
							// 	path: "/software/smartTown",
							// },
							// {
							// 	name: "运维服务",
							// 	path: "/software/bigData",
							// },
						],
					},
					// {
					// 	name: "解决方案",
					// 	pathname: "solveMethod",
					// 	path: "/solveMethod",
					// 	children: [],
					// },
					{
						name: "服务案例",
						pathname: "serviceCategory",
						path: "/serviceCategory",
						children: [],
					},
					{
						name: "诚聘英才",
						pathname: "recruitPeople",
						path: "/recruitPeople",
						children: [],
					},
					{
						name: "联系我们",
						pathname: "contactUs",
						path: "/contactUs",
						children: [],
					},
				],
			};
		},
		mounted() {},
		methods: {
			// 顶部头跳转页面
			topage(index, name, path) {
				// console.log(path);
				// if (path == "/businessSegments") {
				// 	this.navIndex = 2;
				// } else {
				// 	this.navIndex = index;
				// }
				this.index = index;
				this.navClick(this.index, name);
				if (this.$route.path === path) {
					return;
				}
				this.$router.push(path);
			},
			// pc 跳转dt页面
			topageitem(index, path) {
				this.navIndex = index;
				this.$router.push(path);
			},
			// 手机菜单跳转页面
			topage_m(item, index) {
				// console.log("topage_m");
				if (this.$route.path === item.path) {
					return;
				}
				// 关闭的时候将所有的子项菜单 闭合
				// this.navList.map((item, index) => {
				// 	if (item.menuClass) {
				// 		item.menuClass = "glyphicon glyphicon-menu-down";
				// 		$(function () {
				// 			$("#menuitem" + index).collapse("hide");
				// 		});
				// 	}
				// });
				// this.menuClass = "glyphicon glyphicon-menu-down";

				// 页面跳转后 菜单栏会关闭
				this.$router.push(item.path);
				this.navIndex = index;
				this.menuName = item.name;
			},
			// 确认顶部 u li a 高亮
			navClick(index, name) {
				// console.log("navClick");
				this.navIndex = index;
				sessionStorage.setItem("navIndex", index);
				this.menuName = name;
				this.menuClass = "glyphicon glyphicon-menu-down";
			},
			// 手机版本点击事件
			navClick_m(item, index) {
				// console.log("navClick_m");
				this.topage_m(item, index);
			},
			// 手机版本 展开/关闭菜单列表
			menuClick() {
				// console.log("menuClick");
				if (this.menuClass == "glyphicon glyphicon-menu-down") {
					// 展开菜单
					this.menuClass = "glyphicon glyphicon-menu-up";
					$(function() {
						$("#menu").collapse("show");
					});
				} else {
					// console.log("menuClick down");
					$(function() {
						$("#menu").collapse("hide");
					});
					// 关闭的时候将所有的子项菜单 闭合
					this.navList.map((item, index) => {
						if (item.menuClass) {
							item.menuClass = "glyphicon glyphicon-menu-down";
							$(function() {
								$("#menuitem" + index).collapse("hide");
							});
						}
					});
					this.menuClass = "glyphicon glyphicon-menu-down";
				}
			},
			// 手机版本 展开/关闭二级菜单列表
			itemmenuClick(item, index) {
				// console.log("itemmenuClick");
				this.$nextTick(() => {
					if (item.children && item.children.length > 0) {
						// 点击展开/关闭 二级菜单js
						$(function() {
							$("#menuitem" + index).collapse("toggle");
						});

						// 处理监听指向
						if (item.menuClass == "glyphicon glyphicon-menu-down") {
							item.menuClass = "glyphicon glyphicon-menu-up";
						} else {
							item.menuClass = "glyphicon glyphicon-menu-down";
						}
					}
				});
			},
			checkLanguage() {
				this.global.language = "english"
				this.$router.push("/en");
			}
		},
		watch: {
			// 监听路由 当直接在页面上输入路径时 展示高亮菜单
			$route: {
				handler(val, oldval) {
					// console.log("watch");
					this.navList.map((item, index) => {
						// 首先判断首页模块是否相同
						if (item.pathname === val.meta.pathname) {
							this.navClick(index, item.name);
						}
						// 判断是不是首页模块的children页面
						else if (item.children && item.children.length > 0) {
							item.children.map((childitem) => {
								if (childitem.pathname === val.meta.pathname) {
									this.navClick(index, childitem.name);
								}
							});
						}
					});
				},
				// 深度观察监听
				immediate: true, // 一旦监听到路由的变化立即执行
				deep: true,
			},
		},
	};
</script>
<style scoped>
	.lang {
		cursor: pointer;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		display: block;
		height: 90px;
		float: right;
		line-height: 90px;
		margin-left: 28px;
		margin-right: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.lang_icon {
		margin-right: 5px;
		width: 28px;
		height: 28px;
	}

	.lang-phone {

		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		display: block;
		height: 90px;
		float: right;
		line-height: 90px;
		margin-left: 28px;
		margin-right: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 0px;
		top: 0px;
	}


	.lang_icon-phone {
		margin-right: 5px;
		width: 15px;
		height: 15px;
	}

	/* 顶部 */
	#header {
		/* background-color: rgba(21, 45, 52, 0.36); */
		/* background-color: #152d34 !important; */
		transition: all ease 0.6s;
		z-index: 999;
		position: relative;

	}

	/* 导航栏 */
	#header .header-nav {
		background-color: rgba(21, 45, 52, 0.36);
		width: 100%;
		padding-left: 10%;
		padding-right: 20px;
		height: 90px;
	}

	/* 导航栏logo */
	#header .header-nav .header-nav-logo {
		width: 168px;
		height: 100%;
		float: left;
		position: relative;
	}

	/* 导航栏logo图片 */
	#header .header-nav .header-nav-logo img {
		/* width: 168px;
	height: 24px; */

		width: 158px;
		height: 32px;

		position: absolute;
		/* top: -198px; */
		top: 0;
		left: -100px;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	/* 导航栏 导航容器 */
	#header .header-nav .header-nav-wrapper {
		height: 90px;
		float: right;
		margin: 0;
	}

	/* 导航栏 每个导航 */
	#header .header-nav .header-nav-wrapper>li {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 88px;
		float: left;
		position: relative;
	}

	/* 导航栏 每个导航 鼠标在时的样式*/
	#header .header-nav .header-nav-wrapper>li:hover {
		background: #0a3048;
		transition: transform ease 0.5s;
	}

	/* 导航栏 每个导航 鼠标在时下划线 */
	#header .header-nav .header-nav-wrapper>li:hover .underline {
		opacity: 1;
		width: 100%;
		left: 0;
	}

	/* 导航栏 每个导航下面的 a 链接 */
	#header .header-nav .header-nav-wrapper>li>a {
		color: #ffffff;
		font-size: 16px;
		/* font-weight: bold; */
		/* position: relative; */
	}

	/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
	#header .header-nav .header-nav-wrapper>li>a>i {
		display: block;
		position: absolute;
		bottom: -2px;
		left: 50%;
		width: 0;
		height: 2px;
		transition: all 0.6s ease;
		background-color: #0166ff;
	}

	/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
	#header .header-nav .header-nav-wrapper>li>a>span {
		font-size: 12px;
		transition: transform ease 0.5s;
	}

	/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */

	#header .header-nav .header-nav-wrapper>li:hover>a {
		font-weight: bold;
		text-decoration: none;
		background: #0a3048;
	}

	/* #header .header-nav .header-nav-wrapper > li > a:hover {
    font-weight: bold;
    text-decoration: none;
  } */
	/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
	#header .header-nav .header-nav-wrapper>li>a:hover span {
		transform: rotate(180deg);
	}

	/* 导航栏 每个导航 鼠标点击后的样式 */
	#header .header-nav .header-nav-wrapper>li.active {
		font-weight: bold;
		text-decoration: none;
		background: #0a3048 !important;
		/* border-bottom: 2px solid #1e73be; */
	}

	#header .header-nav .header-nav-wrapper>li.active .underline {
		opacity: 1;
		width: 100%;
		left: 0;
	}

	/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
	#header .header-nav .header-nav-wrapper>li.active>a {
		font-weight: bold;
		text-decoration: none;
		/* border-bottom: 2px solid #1e73be; */
	}

	/* 导航栏 每个导航下面的二级导航容器 */
	#header .header-nav .header-nav-wrapper>li>dl {
		display: none;
		position: absolute;
		width: 200px;
		top: 80%;
		left: 0;
		z-index: 999999;
		box-shadow: 0 0 3px 1px #ccc;
		background: #fff;
	}

	/* 导航栏 每个导航下面的二级导航容器的每个导航 */
	#header .header-nav .header-nav-wrapper>li>dl>dt {
		width: 100%;
		padding: 10px 10px 10px 30px;
		border-bottom: 1px solid #ccc;
	}

	#header .header-nav .header-nav-wrapper>li>dl>dt>a {
		color: #707070;
	}

	/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
	#header .header-nav .header-nav-wrapper>li>dl>dt>a:hover {
		text-decoration: none;
	}

	/* 导航栏 滑上一级导航显示二级导航 */
	#header .header-nav .header-nav-wrapper>li:hover dl {
		display: block;
	}

	#header .header-nav .header-nav-wrapper>li>dl>dt:hover {
		cursor: pointer;
		background: #ccc;
	}

	@media screen and (max-width: 1200px) {
		#header .header-nav-m {
			position: relative;
			background-color: rgba(21, 45, 52, 1);
		}

		/* 导航栏logo容器 */
		#header .header-nav-m .header-nav-m-logo {
			height: 80px;
			position: relative;
		}

		/* 导航栏logo图片 */
		#header .header-nav-m .header-nav-m-logo img {
			width: 158px;
			height: 32px;
			/* width: 168px;
		height: 24px; */
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}

		/* 导航栏  菜单容器 */
		#header .header-nav-m .header-nav-m-menu {
			color: #fff;
			height: 50px;
			font-size: 20px;
			line-height: 50px;
			background: #474747;
			position: relative;
		}

		/* 导航栏 菜单图标 */
		#header .header-nav-m .header-nav-m-menu-wrapper {
			position: absolute;
			top: 50%;
			right: 20px;
			margin-top: -20px;
			width: 50px;
			height: 40px;
			color: #fff;
			z-index: 999999;
			font-size: 12px;
		}

		/* 导航栏 */
		#header .header-nav-m .header-nav-m-wrapper {
			position: absolute;
			top: 50px;
			left: 0;
			width: 100%;
			background: #474747;
			z-index: 9999999;
		}

		/* 导航栏 每个导航 */
		.header-nav-m-wrapperli {
			width: 100%;
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #ccc;
			position: relative;
		}

		/* 导航栏 每个导航下面的 a 链接 */
		.header-nav-m-wrapperlia {
			color: #fff;
			font-size: 15px;
			font-weight: bold;
			padding: 15px 0;
			position: relative;
		}

		.header-nav-m-wrapperlia_item {
			color: #fff;
			font-size: 14px;
			font-weight: 400;
			padding: 15px 0;
			position: relative;
		}

		.right {
			right: 20px;
		}

		/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
		#header .header-nav .header-nav-wrapper>li>a>span {
			font-size: 10px;
		}
	}
</style>