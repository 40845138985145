import store from '@/store'

// 全局监听屏幕变动
let screenWidth = document.body.clientWidth
store.dispatch('set_screenWidth', screenWidth)
window.onresize = () => {
  return (() => {
    screenWidth = document.body.clientWidth
    store.dispatch('set_screenWidth', screenWidth)
  })()
}