export default {
	imgUrl: "https://official.feizhisoft.com/",

	language: "chinese",

	Service_Case_En: [{
			//1.不用改 0。等待敬芳切图
			// 1
			id: 1000,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/0.png", //页面介绍图片
			title: "Suzhou - Smart Agriculture and Trade Platform", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "The project comprehensively applies the Feizhi smart agricultural trade digital solution, explores the cultural and creative, ecological, popular and intelligent development path of the old vegetable market, and uses the Internet of Things, cloud computing, network technology, Big data, electronic monitoring and other technologies to realize the intelligent tools of the Farmers'market. Unified use of intelligent electronic scales, cooperation with banks to provide multiple settlement functions, and introduction of APP for online shopping. Now it has successfully transformed many Farmers'market in Suzhou, such as Silkworm Farm, Golden Lion, Jinyi, Huilin Market, etc.",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/Farmers_1.png",
		},
		{
			id: 1001,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/1.png", //页面介绍图片
			title: "Changsha Xiangya Digital Emergency Medical Platform", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "Through the application of wireless technology (WLAN), barcode technology, and mobile computing, medical staff can collect, verify, extract, and input patient diagnosis and treatment data in real-time during emergency rescue services. For emergency medical scenarios, information applications and services have been established from the doctor station, nurse station to the bedside of patients. The application of mobile medical systems has fully connected the patient diagnosis and treatment information chain, achieving full process information management of processes and data, and improving the level of emergency medical rescue information application.",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/rescue.png",
		},
		{
			id: 1002,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/6.png", //页面介绍图片
			title: "Suzhou Xipu Digital Club", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "The project comprehensively applies the Feizhi digital campus solution, targeting the campus environment. By exploring common experiences, hobbies, spatial time, and social relationships, it achieves intelligent multi-dimensional relationships and realizes a new social activity mode. Helping to enrich students' overall university experience and comprehensive development, the project serves 20000 students in Xipu.",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/studentAssociation.png",
		},
		{
			id: 1003,
			parentsId: "Service_Case",
			// cardImg: '@/components/',
			pageImg: "http://official.feizhisoft.com/picture/catalogue/3.png", //页面介绍图片
			title: "Henan - Supply and Marketing Cooperative Smart Rural E-commerce Platform", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "The project comprehensively applies the Feizhi digital campus solution, and through Internet technology, Big data technology and learning analysis technology, it meets teachers' personalized teaching and research needs, teachers' independent research and research needs, as well as teaching discussion and exchange needs, and provides data and technical support and services for Shanghai Qunxing Vocational Education College.",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/businessman.png",
		},
	],



	// 服务案例数据
	Service_Case: [{
			//1.不用改 0。等待敬芳切图
			// 1
			id: 1000,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/0.png", //页面介绍图片
			title: "苏州 - 智慧农贸平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智智慧农贸数字化解决方案，探索老菜场文创化、生态化、网红化、智慧化发展路径，利用物联网、云计算、网络技术、大数据、电子监控等技术，实现农贸市场的智慧化的工具。统一使用智能电子秤，与银行合作提供多种结算功能，引入APP线上购物。现已在苏州成功改造养蚕里、金狮、金益、汇邻市集等多家农贸市场。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/Farmers_1.png",
		},
		{
			id: 1001,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/1.png", //页面介绍图片
			title: "长沙 - 湘雅数字化应急医疗平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "通过无线技术（WLAN）、条形码技术和移动计算等技术的应用，让医护人员在应急救援服务中实时采集、核对、提取和录入病人诊疗数据。针对应急医疗场景，建立了从医生站、护士站到病人床边之间的信息化应用和服务。移动医疗系统的应用使病人诊疗信息链条完整地串联起来，实现了流程和数据的全程信息化管理，提升应急医疗救援信息化应用水平。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/rescue.png",
		},
		{
			id: 1002,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/6.png", //页面介绍图片
			title: "苏州 - 西浦数字化社团", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，针对校园环境，通过挖掘共同经历，共同爱好，空间时间和社会关系等实现智能多维关系，实现新的社交活动模式。有助于丰富学生整体的大学经验和全面发展，项目服务西浦两万名在校学生。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/studentAssociation.png",
		},
		{
			id: 1003,
			parentsId: "Service_Case",
			// cardImg: '@/components/',
			pageImg: "http://official.feizhisoft.com/picture/catalogue/3.png", //页面介绍图片
			title: "河南 - 供销社智慧农村电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，通过互联网技术、大数据技术和学习分析技术，满足了教师的个性化教学研修需求、教师自主研修需求，以及教学研讨与交流需求，为上海群星职业教育学院提供了数据与技术支持与服务。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/businessman.png",
		},
		{
			id: 1004,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/12.png", //页面介绍图片
			title: "上海 - 群星数字化教师发展平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，通过互联网技术、大数据技术和学习分析技术，满足了教师的个性化教学研修需求、教师自主研修需求，以及教学研讨与交流需求，为上海群星职业教育学院提供了数据与技术支持与服务。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/teacherPlatform.png",
		},
		{
			id: 1005,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/15.png", //页面介绍图片
			title: "靖江 - 数字化干部管理系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智干部管理数字化解决方案，针对靖江市干部管理面广量大、统计繁琐等问题，打造的智能、高效、便捷的操作平台，为干部宏观管理，提供数字化支撑和精准参考。主要包括电子化数据交互、单位管理、干部名册、干部成长手册、能力素质、平时表现、干部查询、统计分析、数据交换等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/middle.png",
		},
		{
			id: 1006,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/4.png", //页面介绍图片
			title: "苏州 - 跨境电商大数据中台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智跨境电商大数据中台，密切结合跨境电商新趋势。包含订单管理系统（OMS）、物流管理系统（DMS）、仓储管理系统（WMS）、客户管理系统（CRM）、财务管理系统（FMS）以及供应链管理系统（SCM）等模块功能，全力助力电商出海新征程。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/factory.png",
		},
		{
			id: 1007,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/13.png", //页面介绍图片
			title: "南京 - 智能菜品分析及营养配餐解决方案", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智智能营养配餐及菜品分析解决方案是全球首个能够在手机上完成营养配餐的专业APP。以健康饮食，绿色生活为主，满足用户科学配餐，学习健康知识的需求。独创的智能拍照识别菜品体系，能够通过菜品图片分析菜品营养成分，给出营养指南，实现一键智能认菜！",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/nutrition.png",
		},
		{
			//0 汇机保
			id: 1008,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/33.png", //页面介绍图片
			title: "苏州 - 汇机保手机保险平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智大数据中台，通过整合手机保险行业资源，致力于打造全方位一体化手机保险平台，为数以万计用户提供手机/屏幕保障，手机维修，手机回收，手机资讯等一站式服务。目前已服务汇机保全国两万余家手机门店。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/huijibao.png",
		},
		{
			id: 1009,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/8.png", //页面介绍图片
			title: "苏州 - 电力运维云平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "电力运维在线平台提供设备监控，告警管理，数据分析，能源管理，大屏展示等内容。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/electricity.png",
		},
		{
			id: 1010,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/9.png", //页面介绍图片
			title: "苏州 - 市场能源管理平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "提供仪表管理，缴费管理，统计查询，物业收费等模块，提升市场智能管理水平。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/marketplace.png",
		},
		{
			id: 1011,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/10.png", //页面介绍图片
			title: "苏州 - 柔性生产线数据可视化", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "柔性线路板工厂通过引入BI应用，对设备利用率及故障分析、节拍时间、生产线效率、品质数据加以分析，有效提高了设备利用率、产线产出率、以及产品良率等。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/information.png",
		},
		{
			id: 1012,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/11.png", //页面介绍图片
			title: "苏州 - 制造企业ERP系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "生产管理ERP基于互联网操作平台，采用符合技术发展趋势的SAAS架构，是一款专为 生产制造业及贸易行业打造的企业管理系统，针对企业的办公、电商销售、线下销售、采购、生产、工程、品质、仓库、财务、业务管理等层面提供的一套功能强大、性能完备的综合管理系统。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/epr_1.png",
		},
		{
			//0
			id: 1013,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/32.png", //页面介绍图片
			title: "苏州 - 精益管理平台小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智精益管理平台是一款企业管理软件，为用户提供了方便快捷的精益管理系统，激发员工的工作积极度，随时提出企业的改善建议，促进企业的发展。软件融合社交和游戏的理念，让工作不在枯燥乏味，提高大家的工作效率和办公热情。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/jingyi.png",
		},
		{
			id: 1014,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/20.png", //页面介绍图片
			title: "广州 - 新零售电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智新零售电商平台，运用大数据、物联网、人工智能技术，帮助实体商家完成电商+升级，实现客流倍增与智能留客锁客的智慧新电商。包含严选系统、云商城、商家联盟、本地生活、云商店、顾客全程智能管理系统等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/dianshanggouwu.png",
		},
		{
			id: 1015,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/34.png", //页面介绍图片
			title: "杭州 - 喜从天降婚联网平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智婚联网平台整合婚庆行业生态链，打通“供应链-产业链-服务链”，为婚礼人和婚庆公司提供全方位资源支持。通过全场景的服务链条和创新服务系统，助力传统婚企提高运营效率，优化销售模式，推动传统婚企的数字化升级。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xicongtianjiang.png",
		},
		{
			id: 1016,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/16.png", //页面介绍图片

			title: "苏州 - 智慧律所小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "德根律政是一款微信法务小程序，专门为广大用户和开发者提供法律咨询的小程序。功能涵盖：法律知识展示，法律案例展示，企业日常经营各类法律事项在线咨询，企业日常经营专属合同范本的审查修订，企业日常经营合同的草拟服务，协助寻找法律顾问，出具律师函。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/degen.png",
		},
		{
			id: 1017,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/18.png", //页面介绍图片
			title: "张家港 - 元味稷电商小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "这是一款农产品电商小程序是以农副产品为主题的小程序，商品内容包括家禽肉蛋、有机蔬菜、鲜活水产、油粮五谷等。今日热销、当季推荐、新品尝鲜、生态蔬菜推荐、农家自制产品推荐等主题活动，线上线下互动，全面展示农产品。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/yuanweiji.png",
		},
		{
			id: 1018,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/19.png", //页面介绍图片
			title: "吴江 - 银杏村电商小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "银杏村是一款智慧新农村小程序。给用户提供了农村介绍、乡村特色、信息服务等宣传内容，同时提供了农场监控，积分种植，积分商城，农业认养，农业电商，多级分销，农业众筹等方面的功能，结合物联网大数据等方面功能，很好解决了城市人体验农场种植，养殖的生活。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/yingxincun.png",
		},
		{
			id: 1019,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/21.png", //页面介绍图片
			title: "苏州 - 花香四季电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "绿植花卉电商平台是由本公司开发的以绿植产品为特色的平台，其产品有盆栽单品、盆栽组合、各种多肉、特色花盆、园艺资材、多层花艺等，并且平台功能可接受购买和租赁两种方式。包含 定制租赁 活动摆花 庭院景观  精品案例  积分商城  花香快报等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/huaxiangsiji.png",
		},
		{
			id: 1020,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/22.png", //页面介绍图片
			title: "南京 - 康到营养健康教育平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化解决方案，打造全国领先的健康营养在线教育平台，普及健康，注重营养。包含在线学习(视频、音频、图文) 、考证板块、社群板块、在线商城等功能模块。服务覆盖全国百余万用户。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/kangdaoapp.png",
		},
		{
			id: 1021,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/36.png", //页面介绍图片
			title: "杭州 - 贵金属大数据中台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智贵金属大数据中台，以黄金贵金属为入口，百业互联形成异业资源共享，双向驱动以进一步提升平台用户粘性。打通黄金贵金属生产、黄金贵金属销售、搭建售后、百业联盟管理全产业链通路。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/guijinshu.png",
		},
		{
			id: 1022,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/23.png", //页面介绍图片
			title: "上海 - 沃尔沃销售管理平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "我们为企业提供拓客、触客、管客到成交的一体化智能解决方案，以数据智能引领销售未来.所有客户信息统一管理分权限查看，客户信息公私分明。避免客户跟进重复造成销售撞单。自动聚合客户的合同信息、订单信息、付款信息、票据信息。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xiaoshouapp.png",
		},
		{
			id: 1023,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/25.png", //页面介绍图片
			title: "上海 - 在线教育APP", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "师生互动0距离，老师更专注学生更专心，让教学更有效！还原真实线下课堂场景多样化互动方式，让课堂更有趣！",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/onlineEducation.png",
		},
		{
			id: 1024,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/5.png", //页面介绍图片
			title: "青岛 - 领海智能杂质标识系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "为了解决水质检测繁杂问题，得到更加清晰的检测结果。本应用便于用户操作，能得到更加准确的水质检测结果。可用于标识视频中的杂质，包括杂质标识，踪迹追踪等功能。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wiseDistinguish.png",
		},
		{
			id: 1025,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/28.png", //页面介绍图片
			title: "苏州 - 深度智谷旅游系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: " 智慧旅游主要内容包括办公及管理业务系统的电子政务平台、集旅游信息资讯和商务交易为一体的电子商务平台等。根据实际应用与业务需求，将各平台进行有机整合，实现资源有效利用与调度，构建旅游服务的智慧、旅游管理的智慧和旅游营销的智慧。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/zhihuilvyou.png",
		},
		{
			//0
			id: 1026,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/35.png", //页面介绍图片
			title: "西安 - 智能房产投资决策系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智智能房产投资决策系统是一款定位于房产投资领域统计与分析的数字化分析系统，致力于整合地产行业及政策、人口统计等多维度信息，实现房产投资决策的智能化、信息化管理，并付诸房产决策者运用科学的预测模型，快速、准确地做出决策。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xianfangchan.png",
		},
		{
			id: 1027,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/29.png", //页面介绍图片
			title: "武汉 - 智慧工厂管理系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "在数字化工厂的基础上，利用物联网的技术和设备监控技术加强信息管理和服务；清楚掌握产销流程、提高生产过程的可控性、减少生产线上人工的干预、即时正确地采集生产线数据，以及合理的生产计划编排与生产进度。并加上绿色智能的手段和智能系统等新兴技术于一体，构建一个高效节能的、绿色环保的、环境舒适的人性化工厂。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wiseFactory.png",
		},
		{
			//0
			id: 1028,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/37.png", //页面介绍图片
			title: "上海 - 自然资源部数字化后勤 ", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智后勤管理数字化解决方案，依托信息技术，通过后勤服务社会化、机关事务标准化、机关事务向信息化促进机关事务管理工作提质增效，实现机关后勤精细化、动态化、可视化、智能化服务。实现从“汗水后勤”到“智慧后勤”的转变。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/houqin.png",
		},
		{
			//0
			id: 1029,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/29.png", //页面介绍图片
			title: "苏州 - 广电数字分发中台 ", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智大数据中台，应对苏州广播电视总台微信公众号、短视频平台等不同平台的日益增长的广告管理需求，将新媒体广告的“投放—审核—发布—统计—结算”全流程实现在线化管理，提升了管理效率，优化了新媒体账号运营。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/guangdian.png",
		},
		{
			id: 1030,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/24.png", //页面介绍图片
			title: "苏州 - 海关物联网管理", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "为各行业的设备制造商、方案商及应用开发商提供一站式设备智能化服务。提升传统行业智能化的效率，降低用户的运维成本，助力用户业务发展。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wulianwangapp.png",
		},
		{
			id: 1031,
			parentsId: "Service_Case",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/30.png", //页面介绍图片
			title: "苏州 - 数字化芯片管理", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "苏州原位芯片科技有限责任公司成立于2015年，由清华大学和中科院微电子专业人士共同创立，并获得国内顶尖VC机构千万级投资。公司专注于新型MEMS芯片与模组的研发、生产和销售。掌握40多项领先MEMS技术，拥有芯片设计、工艺开发、流片生产和测试的全流程自主研发、自主生产能力。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/slug.png",
		},
		// {
		// 	id: 1032,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/31.png", //页面介绍图片
		// 	title: "上海 - OyMotion", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "傲意科技主要研发生物信号传感器、生物信号人工智能模式识别、智能辅具、智能仿生义肢等前沿技术和产品。 傲意科技以产业赋能、为客户提供高性价比的康复医疗技术解决方案为使命。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/OyMotion.png",
		// },
		// {
		// 	id: 1034,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/27.png", //页面介绍图片
		// 	title: "南京 - 儿童营养配餐网站", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "幼儿园智能营养配餐与儿童健康管理系统，让每一个儿童获得均衡的膳食营养与全面的健康管理服务。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/wiseFoodChildren.png",
		// },
		// {
		// 	id: 1036,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/7.png", //页面介绍图片
		// 	title: "新加坡 - 融学通校园社交平台", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "利用电子校务平台,包含具有校园特色的应用组件和支持社交与协作构建的关系模型,并对校园用户的隐私提供适当保护的高校校园社交网络系统,对校园内的交流和协作提供了支持。校园活动管理系统以校园活动为主线，基于动态聚类技术，挖掘用户偏好及习惯，智能推和投送精准内容；使用移动终端充当电子票据基于地理位置，显示活动信息，进行现场互动。针对校园环境，通过挖掘共同经历，共同爱好，空间时间和社会关系等实现智能多维关系，实现新的社交活动模式。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/rongxuetong.png",
		// },
		// {
		// 	id: 1037,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/17.png", //页面介绍图片
		// 	title: "心理练习册.小程序", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "助您摆脱情绪困境想自救，不知道怎么办？信息杂乱，不知道怎么选？不确定效果，不能坚持？这是一款三甲精神心理专家联合参与研发的一款心理软件测试小程序。科学方案+疗愈陪伴帮您解决这些问题，摆脱情绪困境。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/praticeSystem.png",
		// },
		// {
		// 	id: 1038,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/2.png", //页面介绍图片
		// 	title: "人脸识别系统", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "人脸识别主要用于身份识别。由于视频监控正在快速普及，众多的视频监控应用迫切需要一种远距离、用户非配合状态下的快速身份识别技术，以求远距离快速确认人员身份，实现智能预警，人脸识别技术无疑是最佳选择。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/artificialIntelligence.png",
		// },
		// {
		// 	id: 1035,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/26.png", //页面介绍图片
		// 	title: "苏州 - 伏波官网", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "伏波电子科技有限公司是一家毫米波太赫兹仪器研发与生产企业，产品面向无线通信、安检安防、航空航天和科学研究等领域。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/fubo.png",
		// },
	],

	// 服务案例数据分类一 数字化解决方案
	Service_Case_One: [{
			id: 1004,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/12.png", //页面介绍图片
			title: "上海 - 群星数字化教师发展平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，通过互联网技术、大数据技术和学习分析技术，满足了教师的个性化教学研修需求、教师自主研修需求，以及教学研讨与交流需求，为上海群星职业教育学院提供了数据与技术支持与服务。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/teacherPlatform.png",
		},
		{
			id: 1005,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/15.png", //页面介绍图片
			title: "靖江 - 数字化干部管理系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智干部管理数字化解决方案，针对靖江市干部管理面广量大、统计繁琐等问题，打造的智能、高效、便捷的操作平台，为干部宏观管理，提供数字化支撑和精准参考。主要包括电子化数据交互、单位管理、干部名册、干部成长手册、能力素质、平时表现、干部查询、统计分析、数据交换等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/middle.png",
		},
		{
			id: 1020,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/22.png", //页面介绍图片
			title: "南京 - 康到营养健康教育平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化解决方案，打造全国领先的健康营养在线教育平台，普及健康，注重营养。包含在线学习(视频、音频、图文) 、考证板块、社群板块、在线商城等功能模块。服务覆盖全国百余万用户。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/kangdaoapp.png",
		},
		{
			id: 1025,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/28.png", //页面介绍图片
			title: "苏州 - 深度智谷旅游系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: " 智慧旅游主要内容包括办公及管理业务系统的电子政务平台、集旅游信息资讯和商务交易为一体的电子商务平台等。根据实际应用与业务需求，将各平台进行有机整合，实现资源有效利用与调度，构建旅游服务的智慧、旅游管理的智慧和旅游营销的智慧。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/zhihuilvyou.png",
		},

		{
			id: 1027,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/29.png", //页面介绍图片
			title: "武汉 - 智慧工厂管理系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "在数字化工厂的基础上，利用物联网的技术和设备监控技术加强信息管理和服务；清楚掌握产销流程、提高生产过程的可控性、减少生产线上人工的干预、即时正确地采集生产线数据，以及合理的生产计划编排与生产进度。并加上绿色智能的手段和智能系统等新兴技术于一体，构建一个高效节能的、绿色环保的、环境舒适的人性化工厂。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wiseFactory.png",
		},
		{
			//0
			id: 1028,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/37.png", //页面介绍图片
			title: "上海 - 自然资源部数字化后勤 ", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智后勤管理数字化解决方案，依托信息技术，通过后勤服务社会化、机关事务标准化、机关事务向信息化促进机关事务管理工作提质增效，实现机关后勤精细化、动态化、可视化、智能化服务。实现从“汗水后勤”到“智慧后勤”的转变。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/houqin.png",
		},

		{
			id: 1030,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/24.png", //页面介绍图片
			title: "苏州 - 海关物联网管理", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "为各行业的设备制造商、方案商及应用开发商提供一站式设备智能化服务。提升传统行业智能化的效率，降低用户的运维成本，助力用户业务发展。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wulianwangapp.png",
		},
		{
			id: 1031,
			parentsId: "Service_Case_One",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/30.png", //页面介绍图片
			title: "苏州 - 数字化芯片管理", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "苏州原位芯片科技有限责任公司成立于2015年，由清华大学和中科院微电子专业人士共同创立，并获得国内顶尖VC机构千万级投资。公司专注于新型MEMS芯片与模组的研发、生产和销售。掌握40多项领先MEMS技术，拥有芯片设计、工艺开发、流片生产和测试的全流程自主研发、自主生产能力。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/slug.png",
		},
	],

	// 服务案例数据分类二 大数据中台
	Service_Case_Two: [{
			id: 1001,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/1.png", //页面介绍图片
			title: "长沙 - 湘雅数字化应急医疗平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "通过无线技术（WLAN）、条形码技术和移动计算等技术的应用，让医护人员在应急救援服务中实时采集、核对、提取和录入病人诊疗数据。针对应急医疗场景，建立了从医生站、护士站到病人床边之间的信息化应用和服务。移动医疗系统的应用使病人诊疗信息链条完整地串联起来，实现了流程和数据的全程信息化管理，提升应急医疗救援信息化应用水平。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/rescue.png",
		},
		{
			id: 1002,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/6.png", //页面介绍图片
			title: "苏州 - 西浦数字化社团", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，针对校园环境，通过挖掘共同经历，共同爱好，空间时间和社会关系等实现智能多维关系，实现新的社交活动模式。有助于丰富学生整体的大学经验和全面发展，项目服务西浦两万名在校学生。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/studentAssociation.png",
		},
		{
			id: 1003,
			parentsId: "Service_Case_Two",
			// cardImg: '@/components/',
			pageImg: "http://official.feizhisoft.com/picture/catalogue/3.png", //页面介绍图片
			title: "河南 - 供销社智慧农村电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智数字化校园解决方案，通过互联网技术、大数据技术和学习分析技术，满足了教师的个性化教学研修需求、教师自主研修需求，以及教学研讨与交流需求，为上海群星职业教育学院提供了数据与技术支持与服务。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/businessman.png",
		},
		{
			id: 1006,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/4.png", //页面介绍图片
			title: "苏州 - 跨境电商大数据中台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智跨境电商大数据中台，密切结合跨境电商新趋势。包含订单管理系统（OMS）、物流管理系统（DMS）、仓储管理系统（WMS）、客户管理系统（CRM）、财务管理系统（FMS）以及供应链管理系统（SCM）等模块功能，全力助力电商出海新征程。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/factory.png",
		},
		{
			//0 汇机保
			id: 1008,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/33.png", //页面介绍图片
			title: "苏州 - 汇机保手机保险平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智大数据中台，通过整合手机保险行业资源，致力于打造全方位一体化手机保险平台，为数以万计用户提供手机/屏幕保障，手机维修，手机回收，手机资讯等一站式服务。目前已服务汇机保全国两万余家手机门店。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/huijibao.png",
		},
		{
			id: 1009,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/8.png", //页面介绍图片
			title: "苏州 - 电力运维云平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "电力运维在线平台提供设备监控，告警管理，数据分析，能源管理，大屏展示等内容。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/electricity.png",
		},
		{
			id: 1010,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/9.png", //页面介绍图片
			title: "苏州 - 市场能源管理平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "提供仪表管理，缴费管理，统计查询，物业收费等模块，提升市场智能管理水平。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/marketplace.png",
		},
		{
			id: 1011,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/10.png", //页面介绍图片
			title: "苏州 - 柔性生产线数据可视化", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "柔性线路板工厂通过引入BI应用，对设备利用率及故障分析、节拍时间、生产线效率、品质数据加以分析，有效提高了设备利用率、产线产出率、以及产品良率等。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/information.png",
		},
		{
			id: 1012,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/11.png", //页面介绍图片
			title: "苏州 - 制造企业ERP系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "生产管理ERP基于互联网操作平台，采用符合技术发展趋势的SAAS架构，是一款专为 生产制造业及贸易行业打造的企业管理系统，针对企业的办公、电商销售、线下销售、采购、生产、工程、品质、仓库、财务、业务管理等层面提供的一套功能强大、性能完备的综合管理系统。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/epr_1.png",
		},
		{
			id: 1021,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/36.png", //页面介绍图片
			title: "杭州 - 贵金属大数据中台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智贵金属大数据中台，以黄金贵金属为入口，百业互联形成异业资源共享，双向驱动以进一步提升平台用户粘性。打通黄金贵金属生产、黄金贵金属销售、搭建售后、百业联盟管理全产业链通路。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/guijinshu.png",
		},
		{
			id: 1022,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/23.png", //页面介绍图片
			title: "上海 - 沃尔沃销售管理平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "我们为企业提供拓客、触客、管客到成交的一体化智能解决方案，以数据智能引领销售未来.所有客户信息统一管理分权限查看，客户信息公私分明。避免客户跟进重复造成销售撞单。自动聚合客户的合同信息、订单信息、付款信息、票据信息。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xiaoshouapp.png",
		},
		{
			//0
			id: 1029,
			parentsId: "Service_Case_Two",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/38.png", //页面介绍图片
			title: "苏州 - 广电数字分发中台 ", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智大数据中台，应对苏州广播电视总台微信公众号、短视频平台等不同平台的日益增长的广告管理需求，将新媒体广告的“投放—审核—发布—统计—结算”全流程实现在线化管理，提升了管理效率，优化了新媒体账号运营。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/guangdian.png",
		},
	],

	// 服务案例数据分类三 人工智能
	Service_Case_Three: [{
			//1.不用改 0。等待敬芳切图
			// 1
			id: 1000,
			parentsId: "Service_Case_Three",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/0.png", //页面介绍图片
			title: "苏州 - 智慧农贸平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "项目综合应用飞智智慧农贸数字化解决方案，探索老菜场文创化、生态化、网红化、智慧化发展路径，利用物联网、云计算、网络技术、大数据、电子监控等技术，实现农贸市场的智慧化的工具。统一使用智能电子秤，与银行合作提供多种结算功能，引入APP线上购物。现已在苏州成功改造养蚕里、金狮、金益、汇邻市集等多家农贸市场。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/Farmers_1.png",
		},
		{
			id: 1007,
			parentsId: "Service_Case_Three",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/13.png", //页面介绍图片
			title: "南京 - 智能菜品分析及营养配餐解决方案", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智智能营养配餐及菜品分析解决方案是全球首个能够在手机上完成营养配餐的专业APP。以健康饮食，绿色生活为主，满足用户科学配餐，学习健康知识的需求。独创的智能拍照识别菜品体系，能够通过菜品图片分析菜品营养成分，给出营养指南，实现一键智能认菜！",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/nutrition.png",
		},
		{
			id: 1024,
			parentsId: "Service_Case_Three",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/5.png", //页面介绍图片
			title: "青岛 - 领海智能杂质标识系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "为了解决水质检测繁杂问题，得到更加清晰的检测结果。本应用便于用户操作，能得到更加准确的水质检测结果。可用于标识视频中的杂质，包括杂质标识，踪迹追踪等功能。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/wiseDistinguish.png",
		},
		{
			//0
			id: 1026,
			parentsId: "Service_Case_Three",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/28.png", //页面介绍图片
			title: "西安 - 智能房产投资决策系统", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智智能房产投资决策系统是一款定位于房产投资领域统计与分析的数字化分析系统，致力于整合地产行业及政策、人口统计等多维度信息，实现房产投资决策的智能化、信息化管理，并付诸房产决策者运用科学的预测模型，快速、准确地做出决策。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xianfangchan.png",
		},
		// {
		// 	id: 1032,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/31.png", //页面介绍图片
		// 	title: "上海 - OyMotion", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "傲意科技主要研发生物信号传感器、生物信号人工智能模式识别、智能辅具、智能仿生义肢等前沿技术和产品。 傲意科技以产业赋能、为客户提供高性价比的康复医疗技术解决方案为使命。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/OyMotion.png",
		// },
		// {
		// 	id: 1038,
		// 	pageImg: "http://official.feizhisoft.com/picture/catalogue/2.png", //页面介绍图片
		// 	title: "人脸识别系统", //项目标题
		// 	oneWord: "一句话介绍", //一句话介绍
		// 	//详情
		// 	detail: "人脸识别主要用于身份识别。由于视频监控正在快速普及，众多的视频监控应用迫切需要一种远距离、用户非配合状态下的快速身份识别技术，以求远距离快速确认人员身份，实现智能预警，人脸识别技术无疑是最佳选择。",
		// 	type: [],
		// 	//路径
		// 	path: "/serviceExample",
		// 	detailImg: "http://official.feizhisoft.com/picture/service/artificialIntelligence.png",
		// },
	],

	// 服务案例数据分类四 移动应用
	Service_Case_Four: [{
			id: 1023,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/25.png", //页面介绍图片
			title: "上海 - 在线教育APP", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "师生互动0距离，老师更专注学生更专心，让教学更有效！还原真实线下课堂场景多样化互动方式，让课堂更有趣！",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/onlineEducation.png",
		},
		{
			//0
			id: 1013,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/32.png", //页面介绍图片
			title: "苏州 - 精益管理平台小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智精益管理平台是一款企业管理软件，为用户提供了方便快捷的精益管理系统，激发员工的工作积极度，随时提出企业的改善建议，促进企业的发展。软件融合社交和游戏的理念，让工作不在枯燥乏味，提高大家的工作效率和办公热情。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/jingyi.png",
		},
		{
			id: 1014,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/20.png", //页面介绍图片
			title: "广州 - 新零售电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智新零售电商平台，运用大数据、物联网、人工智能技术，帮助实体商家完成电商+升级，实现客流倍增与智能留客锁客的智慧新电商。包含严选系统、云商城、商家联盟、本地生活、云商店、顾客全程智能管理系统等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/dianshanggouwu.png",
		},
		{
			id: 1015,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/34.png", //页面介绍图片
			title: "杭州 - 喜从天降婚联网平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "飞智婚联网平台整合婚庆行业生态链，打通“供应链-产业链-服务链”，为婚礼人和婚庆公司提供全方位资源支持。通过全场景的服务链条和创新服务系统，助力传统婚企提高运营效率，优化销售模式，推动传统婚企的数字化升级。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/xicongtianjiang.png",
		},
		{
			id: 1016,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/16.png", //页面介绍图片

			title: "苏州 - 智慧律所小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "德根律政是一款微信法务小程序，专门为广大用户和开发者提供法律咨询的小程序。功能涵盖：法律知识展示，法律案例展示，企业日常经营各类法律事项在线咨询，企业日常经营专属合同范本的审查修订，企业日常经营合同的草拟服务，协助寻找法律顾问，出具律师函。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/degen.png",
		},
		{
			id: 1017,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/18.png", //页面介绍图片
			title: "张家港 - 元味稷电商小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "这是一款农产品电商小程序是以农副产品为主题的小程序，商品内容包括家禽肉蛋、有机蔬菜、鲜活水产、油粮五谷等。今日热销、当季推荐、新品尝鲜、生态蔬菜推荐、农家自制产品推荐等主题活动，线上线下互动，全面展示农产品。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/yuanweiji.png",
		},
		{
			id: 1018,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/19.png", //页面介绍图片
			title: "吴江 - 银杏村电商小程序", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "银杏村是一款智慧新农村小程序。给用户提供了农村介绍、乡村特色、信息服务等宣传内容，同时提供了农场监控，积分种植，积分商城，农业认养，农业电商，多级分销，农业众筹等方面的功能，结合物联网大数据等方面功能，很好解决了城市人体验农场种植，养殖的生活。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/yingxincun.png",
		},
		{
			id: 1019,
			parentsId: "Service_Case_Four",
			pageImg: "http://official.feizhisoft.com/picture/catalogue/21.png", //页面介绍图片
			title: "苏州 - 花香四季电商平台", //项目标题
			oneWord: "一句话介绍", //一句话介绍
			//详情
			detail: "绿植花卉电商平台是由本公司开发的以绿植产品为特色的平台，其产品有盆栽单品、盆栽组合、各种多肉、特色花盆、园艺资材、多层花艺等，并且平台功能可接受购买和租赁两种方式。包含 定制租赁 活动摆花 庭院景观  精品案例  积分商城  花香快报等功能模块。",
			type: [],
			//路径
			path: "/serviceExample",
			detailImg: "http://official.feizhisoft.com/picture/service/huaxiangsiji.png",
		},
	],

	// 诚聘英才数据Top
	recruit_Data_Top: [{
			id: 10,
			title: "五险一金", //项目标题
			oneWord: "具有市场竞争力的薪资+年终奖金+养老保险+医疗保险+工商保险+生育保险+失业保险+住房公积金。", //一句话介绍
		},
		{
			id: 11,
			title: "带薪假期", //项目标题
			oneWord: "享受双份年假：劳动法带薪年假+公司福利年假（凡入职本公司满3年以后，往后每满一年可增加1天年休假）。", //一句话介绍
		},
		{
			id: 12,
			title: "优租房", //项目标题
			oneWord: "协助团队成员申请由政府提供针对人才的住房（精装修拎包入住、价格优惠）。", //一句话介绍
		},
		{
			id: 13,
			title: "生日礼物", //项目标题
			oneWord: "团队成员生日当月公司举办生日聚会并赠送暖心礼物。", //一句话介绍
		},
		{
			id: 14,
			title: "员工活动", //项目标题
			oneWord: "公司不定时举办各类文体比赛、拓展训练、益智游戏等丰富工作以外的生活。", //一句话介绍
		},
	],

	// 诚聘英才数据Bottom
	recruit_Data_Bottom: [{
			id: 15,
			title: "下午茶点", //项目标题
			oneWord: "每个工作日都会有营养又健康的下午茶点，给工作充电。", //一句话介绍
		},
		{
			id: 16,
			title: "政府补贴", //项目标题
			oneWord: "协助团队成员申请园区人才住房补贴、人才薪资补贴、人才子女入学生活补贴。", //一句话介绍
		},
		{
			id: 17,
			title: "年度体检", //项目标题
			oneWord: "公司买单的年度专业健康体检，让你更加了解自身健康状况。", //一句话介绍
		},
		{
			id: 18,
			title: "职业培训", //项目标题
			oneWord: "公司为团队成员甄选各种职业知识培训、专业知识与技能培训提升岗位能力。", //一句话介绍
		},
		{
			id: 19,
			title: "节日津贴", //项目标题
			oneWord: "在享受法定节假日休假同时、公司还有节日津贴+节日礼品，让假期更愉快。", //一句话介绍
		},
	],

	//诚聘英才数据总和
	recruit_Data_Total: [{
			id: 20,
			title: "五险一金", //项目标题
			oneWord: "具有市场竞争力的薪资+年终奖金+养老保险+医疗保险+工商保险+生育保险+失业保险+住房公积金。", //一句话介绍
			// imgUrl: "http://official.feizhisoft.com/icon/recruit/Top0.png",
			imgUrl: "http://official.feizhisoft.com/icon/recruit/top0.png",
		},
		{
			id: 21,
			title: "带薪假期", //项目标题
			oneWord: "享受双份年假：劳动法带薪年假+公司福利年假（凡入职本公司满3年以后，往后每满一年可增加1天年休假）。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/top1.png",
		},
		{
			id: 22,
			title: "优租房", //项目标题
			oneWord: "协助团队成员申请由政府提供针对人才的住房（精装修拎包入住、价格优惠）。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/top2.png",
		},
		{
			id: 23,
			title: "生日礼物", //项目标题
			oneWord: "团队成员生日当月公司举办生日聚会并赠送暖心礼物。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/top3.png",
		},
		{
			id: 24,
			title: "员工活动", //项目标题
			oneWord: "公司不定时举办各类文体比赛、拓展训练、益智游戏等丰富工作以外的生活。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/top4.png",
		},
		{
			id: 25,
			title: "下午茶点", //项目标题
			oneWord: "每个工作日都会有营养又健康的下午茶点，给工作充电。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/bottom0.png",
		},
		{
			id: 26,
			title: "政府补贴", //项目标题
			oneWord: "协助团队成员申请园区人才住房补贴、人才薪资补贴、人才子女入学生活补贴。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/bottom1.png",
		},
		{
			id: 27,
			title: "年度体检", //项目标题
			oneWord: "公司买单的年度专业健康体检，让你更加了解自身健康状况。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/bottom2.png",
		},
		{
			id: 28,
			title: "职业培训", //项目标题
			oneWord: "公司为团队成员甄选各种职业知识培训、专业知识与技能培训提升岗位能力。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/bottom3.png",
		},
		{
			id: 29,
			title: "节日津贴", //项目标题
			oneWord: "在享受法定节假日休假同时、公司还有节日津贴+节日礼品，让假期更愉快。", //一句话介绍
			imgUrl: "http://official.feizhisoft.com/icon/recruit/bottom4.png",
		},
	],
};