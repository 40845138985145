<template>
	<div id="app">
		<!-- <Header></Header> -->
		<router-view :key="$route.fullPath" />
		<!-- <Footer></Footer> -->
		<!-- <GoTop></GoTop> -->
	</div>
</template>

<script>
	export default {
		name: "App",
	};
</script>
<style>
	/* @import "@/style/common.css"; */
	body {
		margin: 0;
	}

	.box-pd {
		margin-left: 10.42% !important;
		margin-right: 10.42% !important;
	}

	.m-1146 {
		margin-right: 11.46%;
		margin-left: 11.46%;
	}

	.p-365 {
		padding-left: 3.65%;
		padding-right: 3.65%;
	}

	.m-l-r-365 {
		padding-left: 3.65%;
		padding-right: 3.65%;
	}

	.bg-ff {
		background-color: #ffffff;
	}

	.bg-fa {
		background-color: #FAF7FA;
	}
</style>