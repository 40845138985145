import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";

const routes = [
	{
		path: "*",
		redirect: "/",
	},
	{
		path: "/",
		component: Layout,
		children: [
			// {
			// 	path: "/",
			// 	name: "首页",
			// 	redirect: "/homePage",
			// },
			{
				path: "/",
				name: "首页",
				component: (resolve) => require(["@/view/homePage"], resolve),
				meta: {
					title: "飞智科技-数字化解决方案，大数据中台，APP开发, 微信小程序开发, 软件定制服务！",
				},
			},
			{
				path: "/en",
				name: "home",
				component: (resolve) => require(["@/view/en/homePage"], resolve),
				meta: {
					title: "Feizhi Technology - Digital Solutions, Big data middle platform, APP development, WeChat applet development, software customization services!",
				},
			},
			{
				path: "/aboutUs",
				name: "aboutUs",
				component: (resolve) => require(["@/view/aboutUs"], resolve),
				meta: {
					title: "关于我们",
					pathname: "aboutUs",
				},
			},
			{
				path: "/businessSegments",
				name: "业务板块",
				component: (resolve) => require(["@/view/businessSegments"], resolve),
				meta: {
					title: "业务板块",
					pathname: "businessSegments",
				},
			},
			{
				path: "/businessSegments/digitalSolutions",
				name: "数字化解决方案",
				component: (resolve) => require(["@/view/digitalSolutions"], resolve),
				meta: {
					title: "数字化解决方案",
					pathname: "digitalSolutions",
				},
			},
			{
				path: "/businessSegments/bigData",
				name: "大数据中台",
				component: (resolve) => require(["@/view/bigData"], resolve),
				meta: {
					title: "大数据中台",
					pathname: "bigData",
				},
			},
			{
				path: "/businessSegments/mobileApps",
				name: "移动应用",
				component: (resolve) => require(["@/view/mobileApps"], resolve),
				meta: {
					title: "移动应用",
					pathname: "mobileApps",
				},
			},
			{
				path: "/businessSegments/artificialIntelligence",
				name: "人工智能",
				component: (resolve) => require(["@/view/artificialIntelligence"], resolve),
				meta: {
					title: "人工智能",
					pathname: "artificialIntelligence",
				},
			},
			{
				path: "/businessSegments/electronicEmbedded",
				name: "车载电子嵌入式",
				component: (resolve) => require(["@/view/electronicEmbedded"], resolve),
				meta: {
					title: "车载电子嵌入式",
					pathname: "electronicEmbedded",
				},
			},{
				path: "/businessSegments/customization",
				name: "定制化开发",
				component: (resolve) => require(["@/view/customization"], resolve),
				meta: {
					title: "定制化开发",
					pathname: "customization",
				},
			},
			{
				path: "/serviceCategory",
				name: "服务案例",
				component: (resolve) => require(["@/view/serviceCategory"], resolve),
				meta: {
					title: "服务案例",
					pathname: "serviceCategory",
				},
			},
			{
				path: "/serviceExample/:id",
				name: "serviceExample",
				component: (resolve) => require(["@/view/serviceExample"], resolve),
				meta: {
					title: "服务案例详情",
					pathname: "serviceCategory",
				},
			},
			{
				path: "/solveMethod",
				name: "solveMethod",
				component: (resolve) => require(["@/view/solveMethod"], resolve),
				meta: {
					title: "解决方案",
					pathname: "solveMethod",
				},
			},
			{
				path: "/recruitPeople",
				name: "recruitPeople",
				component: (resolve) => require(["@/view/recruitPeople"], resolve),
				meta: {
					title: "诚聘英才",
					pathname: "recruitPeople",
				},
			},
			{
				path: "/contactUs",
				name: "contactUs",
				component: (resolve) => require(["@/view/contactUs"], resolve),
				meta: {
					title: "联系我们",
					pathname: "contactUs",
				},
			},
		],
	},
];

export default new Router({
	mode: "history", //改为history  hash模式，去除url里的#
	scrollBehavior: () => ({ y: 0 }),
	routes: routes,
	// scrollBehavior(to, from, saveTop) {
	// 	if (saveTop) {
	// 		return saveTop;
	// 	} else {
	// 		return { x: 0, y: 0 };
	// 	}
	// },
});

Vue.use(Router);

// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch((err) => err);
// };
